// out: false, main: ../../style.scss
///////////////////////////////////////
//////////////// Card v1 //////////////
///////////////////////////////////////
.cards {
  .sbox, .sbox-wrapper, .card-v1, .card-inner { 
    height: 100%;
  }
  .card-v1 {
    .card-inner {
      transition: all 0.3s cubic-bezier(0.71, 0.55, 0.62, 1.57);
      backface-visibility: hidden;
      background-color: $light-grey;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
      will-change: margin;
      &:hover {
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
        margin-top: -.25rem;
        margin-bottom: .25rem;
      }
      .card-link {
        color: black;
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
      }
      .card-header {
        position: relative;
        background-image: url("../images/placeholder-img.png");
        background-size: cover;
        background-position: center;
        height: 300px;
        width: 100%;
        overflow: hidden;
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: transparent;
        }
        .tag {
          position: relative;
          display: inline-block;
          top: 0;
          left: 0;
          padding: 0.5rem 1rem;
          background-color: $light-grey;
        }
        .title {
          position: relative;
          top: 0;
          left: 0;
          margin: 1rem;
        }
      }
    }
  }
}
