// out: false, main: ../style.scss
///////////////////////////////////////
//////////// List Class ///////////////
///////////////////////////////////////
$size: 0.4em;

.list {
  &--unstyled {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  &--inline {
    li {
      display: inline-block;
      &:not(:last-child){
        margin-right: 1em;
      }
    }
  }
  &--ordered {
    counter-reset: item;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    @extend .font--headline-5;
    & .list--ordered { // Nested List stage 1
      & > li {
        padding-left: 2em;
      }
      & .list--ordered { // Nested List stage 2 [Wenn eine weitere Stage benötigt wird, einfach diesen Punkt duplizieren und das padding-left anpassen]
        & > li {
          padding-left: 2.8em; 
        }
      }
    }
    li {
      @extend .font--headline-5;
      display: block;
      padding-left: 1.1em;
      position: relative;
      &::before {
        content: "";
        content: counters(item,".") ".";
        counter-increment: item;
        position: absolute;
        left: 0;
        @extend .font--bold;
      }
    }
  }
  &--bullets {
    @extend .font--headline-5;
    padding-left: 0;
    list-style: none;
    margin-left: 0;
    li {
      @extend .font--headline-5;
      position: relative;
      padding-left: ($size * 2);
      line-height: 120%;
    }
    li::before {
      content: "";
      transform: translateY(-50%);
      top: 0.5em;
      position: absolute;
      left: 0;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4LjUyIDguNTIiPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDojMzUzNTM1O308L3N0eWxlPjwvZGVmcz48dGl0bGU+cHVua3Q8L3RpdGxlPjxnIGlkPSJFYmVuZV8yIiBkYXRhLW5hbWU9IkViZW5lIDIiPjxnIGlkPSJFYmVuZV8xLTIiIGRhdGEtbmFtZT0iRWJlbmUgMSI+PGNpcmNsZSBjbGFzcz0iY2xzLTEiIGN4PSI0LjI2IiBjeT0iNC4yNiIgcj0iNC4yNiIvPjwvZz48L2c+PC9zdmc+");
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      height: $size;
      width: $size;
    }
  }
}
///////////////////////////////////////
/////////////// Tables ////////////////
///////////////////////////////////////
.table {
  &--responsive {
    overflow-x: auto;
    min-height: .1%;
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    th {
      padding:1rem;
      text-align:left;
    }
    td {
      padding:0.5rem 1rem;
      max-width:350px;
      vertical-align:top;
    }
    .table {
      width: 100%;
      overflow-y: scroll;
      margin-bottom: 0;
      min-width:780px;
    }
  }
  &--adaptive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: none;
    min-height: .01%;
    border:1px solid #dedede;
    thead {
      background:#78466E;
      color:#ffffff;
      @media (min-width: $viewport-l){
        display:none;
      }
    }
    th {
      padding:1rem;
      text-align:left;
    }
    tr {
      @media (min-width: $viewport-l){
        display:block;
        max-width:none;
      }
    }
    td {
      padding:0.5rem 1rem;
      vertical-align:top;
      display:block;
      max-width:none;
      @media (min-width: $viewport-l){
        display: inline-block; 
        max-width:350px;
      }
    }
    tbody {
      @media (min-width: $viewport-l){
        display:block;
        max-width:none;
      }
    }
    .table {
      width: 100%;
      margin-bottom: 0;
      @media (min-width: $viewport-l){
        display:block;
        max-width:none;
      }
    }
  }
}