// out: false, main: ../../style.scss
///////////////////////////////////////
//////////////// Card List v1 //////////////
///////////////////////////////////////
.card-list-v1 {
  & > div:not(:first-child) .sbox {
    outline: 5px solid red;
    transform: translateX(-50%);
  }
  // .sbox > div:nth-child(even){
  //     transform: translateX(-50%);
  //     outline: 5px solid red;        
  // }
}