// out: false, main: ../style.scss

////////////////////////////////////////////
// ************* DEBUG MODE ************ //
//////////////////////////////////////////
$debug-mode: false !default;
///////////////////////////////////////
/////////// Viewport //////////////////
///////////////////////////////////////
$viewport-m: 32rem !default;
$viewport-l: 48rem !default;
$viewport-xl: 64rem !default;
///////////////////////////////////////
/////////// Measures //////////////////
///////////////////////////////////////
/*$measure--sm: $viewport-l;
$measure--md: $viewport-xl;
$measure--lg: 90rem;*/
$measure--sm: 800px !default;
$measure--md: 1163px !default;
$measure-mdlg: 1280px !default;
$measure--lg: 1920px !default;
////////////////////////////////////////////
// *************** COLORS ************** //
//////////////////////////////////////////
$color-1: rgb(190, 154, 90) !default;
$color-1--dark: rgb(170, 140, 73) !default;
$color-1--light: rgb(226, 210, 181) !default;

$color-2: rgb(88, 88, 88) !default;
$color-2--dark: rgb(50, 51, 49) !default;
$color-2--light: rgb(231, 231, 231) !default;
$color-2--l2: rgb(243, 243, 243) !default;

$color-3: rgb(50, 51, 49) !default;
$color-3--dark: rgb(50, 51, 49) !default;
$color-3--light: rgb(85, 86, 84) !default;

$color-4: rgb(56, 178, 198) !default; // ab hier nicht angepasst
$color-4--dark: rgb(34, 118, 126) !default;
$color-4--light: rgb(165, 210, 210) !default;

$color-5: rgb(180, 170, 150) !default;
$color-5--dark: rgb(112, 107, 98) !default;
$color-5--light: rgb(225, 218, 200) !default;

$black: rgb(0, 0, 0) !default;
$white: #fff !default;
$dark-grey: #707070 !default;
$light-grey: #F1F1F1 !default;

$color-0--l3: rgb(249, 249, 249) !default;
$color-0--l2: rgb(237, 237, 237) !default;
$color-0--l1: rgb(224, 224, 224) !default;
$color-0: rgb(204, 204, 204) !default;
$color-0--d1: rgb(171, 171, 171) !default;
$color-0--d2: rgb(128, 128, 128) !default;
$color-0--d3: rgb(71, 71, 71) !default;


$color--error: rgb(202, 26, 35) !default;
$color--success: rgb(15, 134, 35) !default;
///////////////////////////////////////
//////////////// Font /////////////////
///////////////////////////////////////
$font-size: 18px !default;
$font-weight: 300 !default;
$font-color: $color-2--dark !default;
$font-family: "HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,sans-serif !default;
$link-color: $color-1 !default;
////////////////////////////////////////////
// ************ FONT STYLES ************ //
//////////////////////////////////////////
$headline-1--min: 40px !default;
$headline-1--max: 85px !default;
$headline-1--letter-spacing: 0 !default;

$headline-2--min: 26px !default;
$headline-2--max: 38px !default;
$headline-2--letter-spacing: 0 !default;

$headline-3--min: 22px !default;
$headline-3--max: 36px !default;
$headline-3--letter-spacing: 0 !default;

$headline-4--min: 18px !default;
$headline-4--max: 24px !default;
$headline-4--letter-spacing: 0 !default;

$headline-5--min: 16px !default;
$headline-5--max: 18px !default;
$headline-5--letter-spacing: 0 !default;

$headline-6--min: 14px !default;
$headline-6--max: 16px !default;
$headline-6--letter-spacing: 0 !default;

$sub-1--min: 16px !default;
$sub-1--max: 18px !default;
$sub-1--letter-spacing: 0 !default;

$sub-2--min: 14px !default;
$sub-2--max: 16px !default;
$sub-2--letter-spacing: 0 !default;

$body-1--min: 14px !default;
$body-1--max: 21px !default;
$body-1--letter-spacing: 0 !default;
$body-1--line-height: 1.75em !default;

$body-2--min: 14px !default;
$body-2--max: 16px !default;
$body-2--letter-spacing: 0 !default;
$body-2--line-height: 1.571em !default;

$caption--min: 12px !default; 
$caption--max: 14px !default;
$caption--letter-spacing: 0 !default;

$font-button--min: 22px !default;
$font-button--max: 22px !default;
$font-button--letter-spacing: 0 !default;

$font-button-medium--min: 11px !default;
$font-button-medium--max: 14px !default;
$font-button-medium--letter-spacing: 0 !default;

$font-button-small--min: 9px !default;
$font-button-small--max: 12px !default;
$font-button-small--letter-spacing: 0 !default;
////////////////////////////////////////////
// ************** BUTTONS ************** //
//////////////////////////////////////////
$button-default-padding: 0.364em 1.091em 0.455em 1.727em !default;

$font-button--font-weight: 500 !default;
$font-button--line-height: 100% !default;

$button-border-radius: 0 !default;

$button-fill: $color-1 !default;
$button-fill--hover: $color-1--dark !default;
$button-transition: 0.2s ease-in-out !default;

$button-solid--color: $white !default;
$button-solid--color-hover: $white !default;

$button-outline--fill: transparent !default;
$button-outline--color: $black !default;
$button-outline--color-hover: $white !default;
$button-outline--border: 2px solid $color-5--dark !default;
$button-outline--border-hover: 2px solid transparent !default;
$button-outline--hover-fill: $color-5--dark !default;

$button-pill--border-radius: 50px !default;

$button-bubble-arrow-size: 10px !default;
// Button Icons
$button-icon-default-hover: true !default;
$button-icon-default-animation: ".hvr-forward" !default;
$button-icon-default-direction: "right" !default;
////////////////////////////////////////////
// ******** PADDINGS | MARGINS ********* //
//////////////////////////////////////////
$p1: 1rem !default;
$p1--i: 1.5rem !default;
$p1--o: 0.5rem !default;

$p2: 2rem !default;
$p2--i: 1.3rem !default;
$p2--o: 0.7rem !default;

$p3: 3rem !default;
$p3--i: 1.3rem !default;
$p3--o: 0.7rem !default;

$p4: 4rem !default;
$p4--i: 1.3rem !default;
$p4--o: 0.7rem !default;

$m1: 1rem !default;
$m2: 2rem !default;
$m3: 3rem !default;
$m4: 4rem !default;