// out: false, main: ../../style.scss 
.accordions-responsive {
  
  .accordion-container {
    .accordion-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      background-color: transparent;
      border-bottom: none;
      padding: 0;
      &.open {
        .accordion-head {
          padding-bottom: 0;
        }
      }
      .accordion-head {
        background: transparent;
        color: $white;
        cursor: pointer;
        // padding: 1rem;
        //padding-right: 2rem;
        @media (min-width: $viewport-l){
          cursor: default;
          padding-right: 0;
        }
        &.open {
          .accordion-title {
            &.icon {
              &__arrow {
                
                /*&::after {
                  -webkit-transform: rotate(-45deg);
                          transform: rotate(-45deg);
                }
                */
              }
            }
          }
          .arrow {
            -webkit-transform-origin: center;
                    transform-origin: center;
            -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg);
          }
        }
        .accordion-title {
          margin: 0;
          position: relative;
          display: block;
          &.icon {
            &__arrow {
              /*&::after {
                content: '';
                position: absolute;
                height: 10px;
                width: 10px;
                border-top: 1px solid $white;
                border-right: 1px solid $white;
                -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                top: 37%;
                opacity: 1;
                right: -2rem;
                -webkit-transition: all 0.5s;
                transition: all 0.5s;
                display: block;
                @media (min-width: $viewport-l){
                  display: none;
                }
              }*/
            }
          }
        }
        .arrow {
          position: absolute;
          right: 0;
          width: 6px;
          -webkit-transform-origin: center;
                  transform-origin: center;
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
          @media (min-width: $viewport-xl){
            display: none;
          }
        }
      }
      .accordion-content {
        display: none;
        padding: 1rem;
        padding-top: 0;
        padding-left: 0;
        @media(min-width: $viewport-xl){
          display: block;
        }
        hr {
          border: 1px solid white;
          margin: 1.5rem 0;
        }
        .custom-bullet-list li {
          margin: 1rem 0;
        }
      }

      &.open {
        .accordion-head {
          .accordion-title {
            &.icon {
              &__arrow {
                
                /*
                &::after {
                  -webkit-transform: rotate(-45deg);
                          transform: rotate(-45deg);
                }
                */
              }
            }
          }
          .arrow {
            -webkit-transform-origin: center;
                    transform-origin: center;
            -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg);
          }
        }
      }
    }
  }
}