///////////////////////////////////////
/////////////// sBox //////////////////
///////////////////////////////////////
.sbox {
  height:100%;
  position:relative;
  .sbox-wrapper {
    background:#fff;
    height:100%;
  }
}