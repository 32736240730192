// out: false, main: ../../style.scss
@function url-friendly-colour($colour) {
  @return '%23' + str-slice('#{$colour}', 2, -1);
}

.hamburger {
  z-index: 1500;
  display: block;
  position: absolute;
  cursor: pointer;
  top: -9px;
  left: 0;
  padding: 0;
  .hamburger-box {
    width: 28px;
    height: 11px;
  }
  .hamburger-inner {
    width: 28px;
    border-radius: 0;
    height:0;
  }
  .hamburger-inner::before, .hamburger-inner::after {
    width: 28px;
    border-radius: 0;
    transform: skewX(-$skew);
    height: 2px;
  }
  .hamburger-inner::before {
    top: -9px;
  }
  .hamburger-inner::after {
    bottom: 0px;
  }
  &.hamburger--stand.is-active .hamburger-inner::before {
    top: auto;
    bottom: 0;
  }
  @media screen and (min-width: $viewport-l) {
      //top: 39%;
      //left: 80px;
    
    .hamburger-box {
      width: 50px;
    }
    .hamburger-inner {
      width: 50px;
    }
    .hamburger-inner::before, .hamburger-inner::after {
      width: 50px;
      height: 4px;
    }
    .hamburger-inner::after {
      bottom: -9px;
    }
  }
}


.navigation-container {
  height: 100%;
  position: absolute;
}

#main-navigation {
  display: none;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba($color-2,0.97);
  transform: translate(-100%,0);
  transform-origin: 0 0;
  transition: transform .5s cubic-bezier(.77,.2,.05,1);
  padding-top: $header-height;
  @media (min-width: $viewport-l) {
    padding-top: $header-height-l;
  }
  @media (min-width: $viewport-xl) {
    padding-top: $header-height-xl;
  }
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 90%;
  .hamburger-open & {
    display: block;
    transform: translate(0,0);
    max-height: 100%;
    overflow: auto;
  }
  a {
    color: $white;
    &:hover {
      text-decoration: none;
    }
  }

  // LVL1 Main Navigation
  .main-nav {
    .lvl-1 {
      &.entry {
        position: relative;
        padding: 0;
        display: block;
        padding-bottom: 1.5em;
        .nav-link {
          padding: 0;
          font-weight: 500;
          display: block;
          &.active {
            color: $color-1;
          }
        }  
      }
      .lvl-2 {
        &.entry {
          position: relative;
          padding: 0;
          display: block;
          padding-top: .4em;
          .nav-link {
            padding: 0;
            font-weight: 300;
            line-height: 85%;
            display: block;
            &.active {
              color: $color-1;
            }
          }  
        }
      }
    }
  }

  .second-nav {
    padding-top: 2rem;
    .lvl-1 {
      &.entry {
        position: relative;
        padding: 0;
        display: inline-block;
        font-size: 1rem;
        padding-bottom: 1em;
        border: none;
        color: $white;
        &.nav-devider::after {
          content: '|';
          padding: 0 .75rem;
        }
        .nav-link {
          padding: 0;
          line-height: 100%;
          font-weight: 500;
          &.lvl-1.active {
            color: $color-1;
          }
        }  
      }
    }
  }  
  
  @media screen and (min-width: $viewport-l) {
    width: auto;
    border-top: none;
    list-style-type: none;
    margin: 0;
    min-width: 450px;
    padding-left: 55px;
    padding-right: 55px;
    // LVL1 Main Navigation
    .main-nav {
      .lvl-1 {
        &.entry {
          border: none;
          .nav-link {
            //font-size: 2.125rem;
            //padding-top: .55rem;
            //padding-bottom: .55rem;
            &:hover {
              color: $color-1;
            }
          }
        }
      }
    }
    .second-nav {
      .lvl-1 {
        &.entry {
          .nav-link {
            font-weight: 300;
            &:hover {
              color: $color-1;
            }
           
          }
        }
      }
    }
  }
}

.social-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  .entry {
    max-width: 32px;
  }
}

@media screen and (min-width: $viewport-l) {
  .social-nav {
    margin-top: 2.5rem;
    #footerlogo & {
      margin-top: 0;
    }
  }
}