// out: false, main: ../../style.scss
.modal-v1 {
  align-items: center;
  display: flex;
  justify-content: center;
  display: inline-block;
  .head {
    background: #DEDBDF;
    border-radius: 16px;
    height: 32px;
    overflow: hidden;
    position: relative;
    width: 162px;
    -webkit-tap-highlight-color: transparent;
    transition: width 300ms cubic-bezier(0.4, 0.0, 0.2, 1),
      height 300ms cubic-bezier(0.4, 0.0, 0.2, 1),
      box-shadow 300ms cubic-bezier(0.4, 0.0, 0.2, 1),
      border-radius 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
    &:not(.expand) {
      cursor: pointer;
      &:hover {
        background: #C2C0C2;
      }
    }
    &.expand {
      border-radius: 6px;
      box-shadow: 0 10px 20px rgba(0,0,0,0.10), 0 6px 6px rgba(0,0,0,0.16);
      height: 200px;
      width: 300px;
      .contents-wrapper {
        &--closed {
          opacity: 0;
          transition: opacity 100ms cubic-bezier(0.4, 0.0, 1, 1);
        }
        &--open {
          opacity: 1;
          transition: opacity 200ms 100ms cubic-bezier(0.0, 0.0, 0.2, 1);
        }
      }
      .contents {
        &--closed {
          transform: scale(1.91);
        }
        &--open {
          transform: scale(1);
        }
      }
    }
  }
  .contents-wrapper {
    &--closed {
      position: absolute;
      transition: opacity 200ms 100ms cubic-bezier(0.0, 0.0, 0.2, 1);
    }
    &--open {
      opacity: 0;
      position: absolute;
      transition: opacity 100ms cubic-bezier(0.4, 0.0, 1, 1);
    }
  }
  .contents {
    &--closed {
      display: flex;
      flex-direction: row;
      transform-origin: 0 0;
      transition: transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
    }
    &--open {
      transform: scale(.55);
      transform-origin: 0 0;
      transition: transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
    }
  }
} 
.name {
  font-size: 14px;
  line-height: 32px;
  margin-left: 10px;
}
.top {
  background: rgb(48, 48, 48);
  display: flex;
  flex-direction: row;
  height: 70px;
  transition: height 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
  width: 300px;
}
.name-large {
  color: #ffffff;
  font-size: 16px;
  line-height: 70px;
  margin-left: 20px;
}
.bottom {
  background: #FFF;
  color:  #474747;
  font-size: 14px;
  // height: 200px;
  padding-top: 5px;
  // width: 300px;
}