// out: false, main: ../../style.scss
$footer-size: 180px;
$footer-size-xl: 105px;
// Container der alles außer den Footer umschließt
.content--fixed-bottom-padding {
  margin-bottom: $footer-size;
  @media (min-width: $viewport-xl) {
    margin-bottom: $footer-size-xl;
  }
}
.fixed-bottom-el {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $footer-size;
  @media (min-width: $viewport-xl) {
    height: $footer-size-xl;
  }
  z-index: -1;
}