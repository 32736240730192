// out: false, main: ../style.scss

@mixin addIcon($icon-path, $direction: $button-icon-default-direction, $height: '1em', $width: '1em', $hover: $button-icon-default-hover, $hover-effect: $button-icon-default-animation) {
  position: relative;
  @if ($direction == "left"){
    padding-left: 2.5em; 
  } @else {
    padding-right: 2.5em;
  }
  &::before {
    content: "";
    background-image: url("#{$icon-path}");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    @if ($hover == true){
      @extend #{$hover-effect};
    }
    position: absolute;
    top: 0.6em;
    @if ($direction == "left"){
      left: 1.2em;
    } @else {
      right: 1.2em;
    }
    height: #{$height};
    width: #{$width};
    @if ($direction == "left"){
      //margin-right: 0.5em;
    } @else {
      //margin-left: 0.5em;
    }
    display: inline-block;
    // font-family: 'Material Design Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  }
}

.button, button {
  @extend .font--button;
  padding: $button-default-padding; 
  background-color: transparent;  
  margin: 0;
  display: inline-block;
  border-radius: $button-border-radius; 
}

// a Default transition überschreiben
a {
  &.button--solid, &.button--outline, &.button--pill, &.button--pill-outline {
    transition: all $button-transition;
    &:hover {
      transition: all $button-transition;
    }
  }
}

.button {
  &--solid {
    @extend .button;
    background-color: $button-fill;
    transition: all $button-transition;
    color: $button-solid--color;
    border: 2px solid $button-fill;
    &:hover {
      background-color: $button-fill--hover;
      color: $button-solid--color-hover;
      border: 2px solid $button-fill--hover;
    }
  }
  &--outline {
    @extend .button;
    background-color: $button-outline--fill;
    transition: all $button-transition;
    color: $button-outline--color;
    border: $button-outline--border;
    &:hover {
      background-color: $button-outline--hover-fill;
      color: $button-outline--color-hover;
      border: $button-outline--border-hover;
    }
  }
  &--pill {
    @extend .button;
    @extend .button--solid;
    border-radius: $button-pill--border-radius;
  }
  &--pill-outline {
    @extend .button;
    @extend .button--outline;
    border-radius: $button-pill--border-radius;
  }
}

.button, button, .button--solid, .button--outline, .button--pill, .button--pill-outline {
  &.bubble--bottom {
    position: relative;
    margin-bottom: $button-bubble-arrow-size;
    &:hover {
      &::before {
        border-color: $button-fill--hover transparent transparent;
      }
    }
    &::before {
      content: '';
      position: absolute;
      border-style: solid;
      left: calc(50% - #{$button-bubble-arrow-size});
      bottom: -#{$button-bubble-arrow-size}; 
      border-width: $button-bubble-arrow-size $button-bubble-arrow-size 0;
      border-color: $button-fill transparent transparent;
      transition: all $button-transition;
    } 
  }
  &.bubble--top {
    position: relative;
    margin-top: $button-bubble-arrow-size;
    &:hover {
      &::before {
        border-color: transparent transparent $button-fill--hover;
      }
    }
    &::before {
      content: '';
      position: absolute;
      border-style: solid;
      right: calc(50% - #{$button-bubble-arrow-size});
      top: -#{$button-bubble-arrow-size}; 
      border-width: 0 $button-bubble-arrow-size $button-bubble-arrow-size;
      border-color: transparent transparent $button-fill;
      transition: all $button-transition;
    }
    // &--outline {
    //   position: relative;
    //   margin-top: $button-bubble-arrow-size;
    //   &:hover {
    //     &::before {
    //       border-style: solid;
    //       left: calc(50% - #{$button-bubble-arrow-size});
    //       bottom: -#{$button-bubble-arrow-size}; 
    //       border-width: $button-bubble-arrow-size $button-bubble-arrow-size 0;
    //       border-color: $button-fill transparent transparent;
    //       transform: rotate(-180deg);
    //     }
    //   }
    //   &::before {
    //     content: '';
    //     position: absolute;
    //     right: calc(50% - #{$button-bubble-arrow-size});
    //     top: -#{$button-bubble-arrow-size}; 
    //     height: 1em;
    //     width: 1em;
    //     border-top: 2px solid black;
    //     border-right: 2px solid black;
    //     transform: rotate(-45deg);
    //     transition: all $button-transition;
    //   }
    // }
  }
  &.bubble--left {
    position: relative;
    margin-left: $button-bubble-arrow-size;
    &:hover {
      &::before {
        border-color: transparent $button-fill--hover transparent transparent;
      } 
    }
    &::before {
      content: '';
      position: absolute;
      border-style: solid;
      left: -#{$button-bubble-arrow-size};
      top: calc(50% - #{$button-bubble-arrow-size}); 
      border-width: $button-bubble-arrow-size $button-bubble-arrow-size $button-bubble-arrow-size 0;
      border-color: transparent $button-fill transparent transparent;
      transition: all $button-transition;
    }
  }
  &.bubble--right {
    position: relative;
    margin-right: $button-bubble-arrow-size;
    &:hover {
      &::before {
        border-color: transparent transparent transparent $button-fill--hover;
      }
    }
    &::before {
      content: '';
      position: absolute;
      border-style: solid;
      right: -#{$button-bubble-arrow-size};
      top: calc(50% - #{$button-bubble-arrow-size}); 
      border-width: $button-bubble-arrow-size 0 $button-bubble-arrow-size $button-bubble-arrow-size;
      border-color: transparent transparent transparent $button-fill;
      transition: all $button-transition;
    }
  }
}



// TESTING ONLY
.button-icon-font--before {
  @include addIcon("../icons/feather/arrow-right.svg", "right", true);
}