// out: style.min.css, compress: true, sourceMap: false,sourceMapFileInline: false, autoprefixer: last 3 versions, groupmedia: true

///////////////////////////////////////
///////////// Variables ///////////////
///////////////////////////////////////
@import "components/vars";
///////////////////////////////////////
///////////// libraries ///////////////
///////////////////////////////////////
//@import "components/lib/aos";
@import "components/lib/hamburgers/hamburgers";
@import "components/lib/hover";
//@import "components/lib/simplelightbox";
@import "components/lib/swiper";
//@import "components/lib/materialdesignicons/materialdesignicons";
//@import "components/lib/pretty-checkbox/pretty-checkbox";
//@import "components/lib/material-icons";
 
///////////////////////////////////////
//////////////// Theme ////////////////
/////////////////////////////////////// 
@import "components/theme";  
 
///////////////////////////////////////
/////////////// General ///////////////
///////////////////////////////////////
@import "components/utility";
@import "components/normalize";
@import "components/grid"; 
@import "components/resets";   
@import "components/forms";
@import "components/margins-paddings"; 
@import "components/font-styles";  
@import "components/blocks";  
@import "components/buttons"; 
@import "components/lists-tables";  
@import "components/colors"; 
@import "components/sbox";  

///////////////////////////////////////
/////////////// Modules ///////////////
///////////////////////////////////////
  
// Hier sind die Module hinzuzufügen, die verwendet werden. Nichtverwendete bitte löschen
//@import "components/modules/accordions"; 
@import "components/modules/accordions-responsive"; 
//@import "components/modules/footer-v1";  
//@import "components/modules/footer-v2"; 
//@import "components/modules/gallery";
@import "components/modules/obj-fit-header";  
@import "components/modules/slider-v1"; 
@import "components/modules/navigation-v1"; 
@import "components/modules/slider-full-bg-centred-text";
//@import "components/modules/form-v1";
@import "components/modules/form-v2";
@import "components/modules/split-modul";
@import "components/modules/card-v1";
//@import "components/modules/miniatures-slider";  
//@import "components/modules/content-loader";   
//@import "components/modules/img-tile";  
//@import "components/modules/yt-video-v1";  
@import "components/modules/modal-v1";  
@import "components/modules/card-list-v1";   
@import "components/modules/fixed-bottom-el";
//@import "components/modules/css-parallax";
//@import "components/modules/css-parallax-image";
///////////////////////////////////////
/////////////// Custom ////////////////
///////////////////////////////////////

// Hier wird das CSS mit den eigenen Styles für die Website eingebunden z.B.:
@import "main";