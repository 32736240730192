// out: false, main: ../style.scss

.color-1 {
  background-color: $color-1;
}
.color-1 {
  &--dark {
    background-color: $color-1--dark;
  }
  &--light {
    background-color: $color-1--light;
  }
}

.fontcolor-1 {
  color: $color-1;
}
.fontcolor-1 {
  &--dark {
    color: $color-1--dark;
  }
  &--light {
    color: $color-1--light;
  }
}

.color-2 {
  background-color: $color-2;
}
.color-2 {
  &--dark {
    background-color: $color-2--dark;
  }
  &--light {
    background-color: $color-2--light;
  }
  &--l2 {
    background-color: $color-2--l2;
  }
}

.fontcolor-2 {
  color: $color-2;
}
.fontcolor-2 {
  &--dark {
    color: $color-2--dark;
  }
  &--light {
    color: $color-2--light;
  }
}

.color-3 {
  background-color: $color-3;
}
.color-3 {
  &--dark {
    background-color: $color-3--dark;
  }
  &--light {
    background-color: $color-3--light;
  }
}

.fontcolor-3 {
  color: $color-3;
}
.fontcolor-3 {
  &--dark {
    color: $color-3--dark;
  }
  &--light {
    color: $color-3--light;
  }
}

.color-4 {
  background-color: $color-4;
}
.color-4 {
  &--dark {
    background-color: $color-4--dark;
  }
  &--light {
    background-color: $color-4--light;
  }
}

.fontcolor-4 {
  color: $color-1;
}
.fontcolor-4 {
  &--dark {
    color: $color-4--dark;
  }
  &--light {
    color: $color-4--light;
  }
}

.color-5 {
  background-color: $color-5;
}
.color-5 {
  &--dark {
    background-color: $color-5--dark;
  }
  &--light {
    background-color: $color-5--light;
  }
}

.fontcolor-5 {
  color: $color-5;
}
.fontcolor-5 {
  &--dark {
    color: $color-5--dark;
  }
  &--light {
    color: $color-5--light;
  }
}

.color-white {
  background: $white;
}

.fontcolor-white {
  color: $white;
}

.fontcolor-black {
  color: $black;
}
