// out: false, main: ../../style.scss
.slider-v1 {
  position: relative;
  @media(min-width: $viewport-xl){
    margin-left: 3rem;
    margin-right: 3rem;
  }
  &--container {
    position: relative;
    overflow: hidden;
    .swiper-wrapper {
      .swiper-slide {
        height: auto;
        .slider-tile {
          height: 100%;
          background: transparent;
        }
      }
    }
  }
}