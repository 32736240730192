// out: false, main: style.scss

.grd {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (min-width: $viewport-xl) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    /*&.measure--lg {
      padding-left: 5rem;
      padding-right: 5rem;
    }*/
  }
  @media (min-width: 1760px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
.grd--lg {
  @media (min-width: 1760px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.p1o--lg {
  padding: 0;
  @media (min-width: $viewport-xl) {
    padding: $p1--o;
  }
}
.p1i--lg {
  padding: $p1--i 0;
  @media (min-width: $viewport-xl) {
    padding: $p1--i;
  }
}


.grd-align--align-end--xl {
  @media (min-width: $viewport-xl) {
    align-items: flex-end;
  }
}


.h1 {
  line-height: 110%;
  @extend .m1--b;
}

.h1-leistungen {
  @include calc-font-size(116px, 35px);
}

.font--body-3 {
  @include calc-font-size(18px, 14px);
  line-height: 1.571em;
}


section { 
  //background: $white;
  &.sbox-wrapper {
    background: none;
  }
  
  @media (min-width: $viewport-xl) {
    &.content-wrapper {
      background: $light-grey;
      .content-measure {
        background: $white;
      }
    }
  }
}
.section-devider--b {
  border-bottom: 1px solid $color-2--light;
}
.section-devider--t {
  border-top: 1px solid $color-2;
}


// SECTION TEXT-IMAGE-CONTAINER
.text-img-container--lg {
  position: relative;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  #praxis & { // NOTE auf der Startseite
    padding-top: 20%; 
    padding-bottom: 13%;
  }
  @media (min-width: $viewport-xl) {
    padding-top: 9%;
    padding-bottom: 14%;
    #praxis & { // NOTE auf der Startseite
      padding-bottom: 9%;
    }
    &.img-left {
      .text-img-container--textbox-outer {
        .text-img-container--textbox-inner {
          flex-direction: row-reverse;
        }
      }
      .text-img-container--imgbox-outer {
        left: 0;
      }
    }
    &.img-right {
      .text-img-container--textbox-outer {
        .text-img-container--textbox-inner {
          flex-direction: row;
        }
      }
      .text-img-container--imgbox-outer {
        right: 0;
      }
    }
  }
  .text-img-container--textbox-outer {
    width: 100%;
    order: 1;
    #praxis & { // NOTE auf der Startseite
      order: 2;
      @media (min-width: $viewport-xl) {
        order: 2;
      }
    }  
    .text-img-container--textbox-inner {
      .text-img-container--textbox {
        h2 {
          margin-bottom: 1rem;
        }
        @media (min-width: $viewport-m) {
          h2 {
            margin-bottom: 1.5rem;
          }
          #praxis & { // NOTE auf der Startseite
            max-width: 45rem;
          }
        } 
        @media (min-width: $viewport-xl) {
          #praxis & { // NOTE auf der Startseite
            max-width: 35rem;
          }
          p {
            text-align: center;
          }
        } 
      }
    }
  }
  .text-img-container--imgbox-outer {
    order: 2;
    #praxis & { // NOTE auf der Startseite
      order: 1;
      @media (min-width: $viewport-xl) {
        order: 1;
      }
    }  
    @extend .p1;
    #praxis & { // NOTE auf der Startseite
      &:before {
        content: 'Im Herzen der List';
        @extend .font--uppercase;
        @extend .fontcolor-2;
        @extend .font--center;
        @extend .font--headline-1;
        @extend .font-rufo;
      }
    }
    @media (min-width: $viewport-xl) {
      #praxis & {
        &:before {
          content: '';
          display: none;
        }
      }
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 0;
    }
    .objectfit-el {
      width: 100%;
      height: 100%;
    }
  }
  button {
    margin-top: 1rem;
    @media (min-width: $viewport-xl) {
      margin-top: 2rem;
    }
  }
}
// !SECTION TEXT-IMAGE-CONTAINER

// SECTION QUOTATION
.quotation-container {
  width: 100%;
  height: 100%; 
  position: absolute; 
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.quotation {
  //position: relative;
  padding-top: 16%;
  @media (min-width: $viewport-m) {
    padding-top: 19%;
  } 
  @media (min-width: $viewport-xl) {
    padding-top: 15%;
  }
  &:before {
    content: '';
    position: absolute;
    top: -0.563rem;
    left: 1.5rem;
    width: 29%;
    .quotation-container & {
      left: .5rem;
      top: 1rem;
      width: 22.68%;
    }
    height: 100%;
    background: url('/standards/images/quotation-marks.svg');
    background-repeat: no-repeat;
    transition: width .5s ease-in-out;
    @media (min-width: $viewport-m) {
      width: 24%;
    }
    @media (min-width: $viewport-xl) {
      top: -1rem;
      left: 5rem;
      width: 16%;
      max-width: 310px;
      .quotation-container & {
        top: 1rem;
        width: 22.68%;
        left: 2.5rem;
      }
    }
  }
}
// !SECTION QUOTATION ENDE

.p3--lr--xl {
  @media (min-width: $viewport-xl) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.float {
  &--left {
    float: left;
    &--xl {
      @media (min-width: $viewport-xl) {  
        float: left;
      }
    }
  }
  &--right {
    float: right;
    &--xl {
      @media (min-width: $viewport-xl) {  
        float: right;
      }
    }
  }
  &--none {
    float: none;
    &--xl {
      @media (min-width: $viewport-xl) {  
        float: none;
      }
    }
  }
}

.font {
  &--left {
    &--xl {
      @media (min-width: $viewport-xl) {  
        text-align: left;
      }
    }
  }
  &--right {
    &--xl {
      @media (min-width: $viewport-xl) {  
        text-align: right;
      }
    }
  }
  &--center {
    &--xl {
      @media (min-width: $viewport-xl) {  
        text-align: center;
      }
    }
  }
}
.cknt-text a {
  font: inherit;
}
.button--white {
  background: $white;
  color: $black;
  border: none;
  font-size: 1.125rem;
  &:hover {
    background: $color-1--light;
    color: $black;
    border: none;
  }
}

button.button-icon-arrow {
  //@mixin addIcon('/standards/images/arrow-button.svg', $icon-path-hover: '/standards/images/arrow-button.svg', $direction: 'right', $hover: $button-icon-default-hover, $hover-effect: $button-icon-default-animation);
  @include addIcon('/standards/images/arrow-button.svg', $direction: 'right', $height: '0.455em', $width: '0.565em', $hover: $button-icon-default-hover, $hover-effect: $button-icon-default-animation);
  padding-right: 2.4em;
}

button.button-back {
  @include addIcon('/standards/images/arrow-button-back.svg', $direction: 'left', $height: '0.455em', $width: '0.565em', $hover: $button-icon-default-hover, $hover-effect: ".hvr-backward");
  padding-left: 1.7em;
  &::before {
    left: .75em;
  }
}

button.button--round {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: $color-3--light;
  border: 1px solid $color-3;
  padding: 0;
  display: flex;
  justify-content: center;
}

.height100 {
  height: 100%;
}

.grd-align-self {
  &--end {
    align-self: flex-end;
  }
}

.iframe-container .note-text, .maps-container .note-text, .youtube-frame .note-text {
  @extend .font-rufo;
  font-size: 1.5rem;
  @media (min-width: $viewport-xl) {
    font-size: 2.125rem;
  }
  line-height: 1.088em;
  font-weight: 300;
}
#map2 {
  padding-bottom: 100%;
  @media (min-width: $viewport-xl) {
    padding-bottom: 36%;
  }
}

.smooth-scroll-arrow-down {
  width: 13px;
  height: 10px;
  margin-bottom: 2px;
  padding-left: 10px;
  box-sizing: content-box;
}

.arrow-container {
  padding-left: 10px;
  box-sizing: content-box;
}
.arrow { 
  &--down {
    transform: rotate(90deg);
  }
  &--left {
    transform: rotate(180deg);
  }
  &--white {
    .cls-1, .cls-2 {
      fill: $white;
    }
  }
  &--bronze {
    .cls-1, .cls-2 {
      fill: $color-1;
    }
  }
} 

.smooth-scroll-content {
  display: none;
}
@media (min-width: $viewport-xl) {  
  .padding-xl { 
    padding: 90px 0;
  }
  .maxwidth-measure {
    &--sm {
      max-width: $measure--sm;
    }
  }
  .smooth-scroll-wrapper, .smooth-scroll-wrapper-inner {
    position: relative;
  }
  .js-smooth-scroll.smooth-scroll-content {
    transform: rotate(90deg) skewX(-$skew);
    transform-origin: right bottom;
    background: $color-1;
    display: inline;
    position: absolute;
    color: $white;
    z-index: 450;
    padding: .75rem 1.75rem;
    line-height: 100%;
    font-size: 1.25rem;
    font-weight: 500;
    max-height: 165px;
    top: -43px;
    &--left {
      left: 0;
      top: 0;
    }
    &--right {
      right: 0;
      transform-origin: left top;
    }
    &--middle {
      right: 50%;
      top: 0;
    }
    &:hover {
      color: $white;
      text-decoration: none;
    }
    .scroll-indicator-text {
      transform: skewX($skew);
      display: flex;
      align-items: center;
      svg  {
        margin-left: .625rem;
      }   
    }
  }
}

ol, ul {
  padding-left: 1em;
}

// SECTION SWIPER ARROWS
.swiper-button-prev, .swiper-button-next {
  display: none;
}
#galerieswiper .swiper-pagination {
  bottom: 0;
  top: auto;
}
@media (min-width: $viewport-l) { 
  .swiper-button-next, .swiper-button-prev {
    display: none;
  }
}
//ANCHOR DESKTOP
@media (min-width: $viewport-xl) { 
  .swiper-button-prev, .swiper-button-next {
    .headerswiper-container &, #galerie & {
      border: none;
      outline: none;
      width: 57px;
      height: 68px;
      background: rgba(118,118,118,.86);
      border-radius: 0 15px 15px 0;
      display: block;
      padding: 22px 0;
      top: 58%;
      text-align: center;
      svg {
        max-height: 24px;
        max-width: none;
        transform-origin: center;
      }
      
    }
    .headerswiper-container & {
      background: rgba(209,209,209,.86);
    }
  }
  .swiper-button-prev {
    .headerswiper-container &, #galerie & {
      left: 0;
    }
  }
  .swiper-button-next {
    .headerswiper-container &, #galerie & {
      right: 0;
      border-radius: 15px 0 0 15px;
    }
  }
}

// !SECTION SWIPER ARROWS ENDE

@media (min-width: $viewport-xl) { 
  #piwikframe {
    height: 150px!important;
  }
}

.icon-schnarchtherapie {
  background: url(/standards/images/icon-schnarchtherapie.svg) no-repeat center left/100% auto;
}
.icon-behandlungstechnik {
  background: url(/standards/images/icon-behandlungstechnik.svg) no-repeat center left/100% auto;
}
.icon-managertermin {
  background: url(/standards/images/icon-managertermin.svg) no-repeat center left/100% auto;
}
.icon-angstpatient {
  background: url(/standards/images/icon-angstpatienten.svg) no-repeat center left/100% auto;
}

// SECTION STICKY HEADER
#cookie {
  position: fixed;
  z-index: 1700;
  bottom: 0;
  left: 0;
  right: 0;
}
body {

  padding-top: $header-height;
  transition: padding .5s ease-in-out;
  @media (min-width: $viewport-l) {
    padding-top: $header-height-l;
    &.is-sticky {
      padding-top: $header-height-sticky-l;
      transition: padding .3s linear;
    }
  }
  @media (min-width: $viewport-xl) {
    padding-top: $header-height-xl;
    &.is-sticky {
      padding-top: $header-height-sticky-xl;
      transition: padding .3s linear;
    }
  }
}
#header {
  position: fixed;
  padding: 0 1.5rem;
  top: 0;
  left: 0;
  right: 0;
  background: $white;
  z-index: 500;
  //height: $header-height;
  border-bottom: 1px solid $color-2--light;
  .is-sticky & {
    box-shadow: 0 16px 20px 0 rgba($color-2--dark,.07);
  }
  .logo {
    img {
      max-height: 56px;
      display: block;
      padding: .3rem 0;
      margin: 0 auto;
    }
  }
  .navi-wrapper {
    width:15%;
    @media (min-width: $measure-mdlg) {
      width:33%;
    }
  }
  .logo-wrapper {
    width:45%;
    z-index: 5;
    @media (min-width: $viewport-l) {
      width:35%;
    }
    @media (min-width: $measure-mdlg) {
      width:27%;
    }
  }
  .header-buttons {
    z-index: 4;
    width:40%;
    @media (min-width: $viewport-l) {
      width:50%;
    }
    @media (min-width: $measure-mdlg) {
      width:40%;
    }
    .header-buttons-container {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
    }
    .header-button-wrapper{
      position: relative;
      margin-bottom: -6px;
    }
    .header-button-bg1, .header-button-bg2 {
      background: $color-2;
      position: absolute;
      width: 50%;
      height: 100%;
      transform: skewX(-30deg);
      transform-origin: bottom;
      z-index: 20;
    }
    .header-button-bg1 {
      //right: 32px;
      right: 23px;
      margin-right: -23px;
    }
    .header-button-bg2 {
      left: 0;
      margin-right: -23px;
    }
   
    #button-document-wrapper { 
      z-index: 10;   
    }
    #button-phone-wrapper {
      z-index: 5;
      .header-button-bg1, .header-button-bg2 {
        background: $color-1;
      }
    }
    .header-button {
      color: $white;
      margin-right: -23px;
      padding: 0.8rem;
      background: transparent;
      max-height: 38px;
      position: absolute;
      font-size: 1.063rem;
      padding: 20px 30px;
      line-height: 100%;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      overflow: hidden;
      z-index: 30;
      a {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      &::before {
        content: '';
        display: block;
        height: 56px;
        width: 33px;
        transform: skewX(-30deg);
        background: white;
        transform-origin: bottom;
        left: -33px;
        bottom: 0;
        border-bottom: 6px solid $color-1--light;
        position: absolute;
      }
      &#button-document {
        .button-icon {
          width: 23px;
          height:26px;
          background: $color-2 url(/standards/images/icon-cal.svg) no-repeat left center/100%;
        }
      }
      &#button-phone {
        position: relative;
        padding: 20px 26px;
        &::before {
          //background: $color-2;
          border: none;
        }
        .button-icon {
          height:24px;
          width: 24px;
          background: $color-1 url(/standards/images/icon-phone.svg) no-repeat left center/100%;
        }
      }
      a, a:hover {
        color: $white;
        text-decoration: none;
      }
      .button-icon {
        display: block;
      }
      .button-font, .arrow {
        display: none;
        @extend .font-rufo;
      }
      
    }

    .header-button-wrapper {
      position: relative;
    }
    #contact-dropdown {
      position: absolute;
      z-index: -10;
      top: 0;
      padding: 72px 30px 35px 35px;
      width: auto;
      right: -23px;
      min-width: 250px;
      background: $color-1;
      display: none;
      color: $white;
      &::before {
        content: '';
        height: 34px;
        width: 90%;
        top: 0;
        left: 0;
        background: $white;
        position: absolute;
        display: block;
      }
      .phonenumber {
        font-size: 2.563rem;
        line-height: 1em; 
        a {
          color: $white;
          text-decoration: none;
        }
      }
      .oeffnungszeiten {
        font-size: .75rem;
        padding-top: 6px;
        line-height: 1.3rem;
      }
      button {
        margin-top: .625rem;
        width: 100%;
        a {
          color: $black;
          font-size: 17px;
          text-decoration: none;
        }
      }
    }
  }
  // ANCHOR TABLET
  @media (min-width: $viewport-l) {
    //height: $header-height-l;
    padding-left: 5rem;
    transition: height .3s linear;
    .is-sticky & {
      //height: $header-height-sticky-l;
      transition: height .3s linear;
    }
    .logo {
      transition: height .3s linear;
      img {
        padding: .7rem 0;
        height: 100px;
        max-height: none;
      }
      .is-sticky & {
        transition: height .3s linear;
        img {
          height: 62px;
          max-height: none;
        }
      }
    }
  }
  // ANCHOR DESKTOP
  @media (min-width: $viewport-xl) {
    //height: $header-height-xl;
    transition: height .3s linear;
    .is-sticky & {
      //height: $header-height-sticky-xl;
      transition: height .3s linear;
    }
    .logo {
      transition: height .3s linear;
      img {
        padding: 1rem 0;
        height: 145px;
        max-height: none;
      }
      .is-sticky & {
        transition: height .3s linear;
        img {
          padding: .7rem 0;
          height: 90px;
          max-height: none;
        }
      }
    }
    .header-buttons {
      .header-button-bg1, .header-button-bg2 {
        margin-right: 0;
      }
      .header-button-bg1 {
        right: 32px;
      }
      .header-button {
        margin-right: 0;
        max-height: 56px;
        font-size: 1.063rem;
        min-width: 102px;
        padding: 20px 40px 20px 50px;
        line-height: 100%;
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &#button-document {
          .button-icon {
            width: 26px;
            height: 29px;
          } 
          .arrow-container {
            height: 17px; 
            width: 15px;
          }
        }
        &#button-phone {
          cursor: pointer;
          padding: 20px 40px 20px 50px;
          .button-icon {
            height: 23px;
            width: 23px;
          }
          .arrow-container {
            height: 15px; 
            width: 11px;
          }
          &::before {
            background: $white;
            border-bottom: 6px solid $color-1--light;
          }
        }
        .button-font, .arrow {
          display: block;
        }
        .button-font {
          padding-left: 10px;
          padding-right: 14px;
        }
        &.is-active {
          .arrow {
            transform: rotate(-90deg);
          }
        }
      }
      #contact-dropdown {
        width: 100%;
        left: 0;
        min-width: none;
        right: auto;
        &::before {
          display: none;
          content: '';
          height: 56px;
          width: 56px;
        }
      }
    }
  }
}
// !SECTION STICKY HEADER ENDE


// SECTION SLIDER / PAGE HEADER
#pageheader {
  .headerswiper-container {
    height: 100%;
    #headerswiper .swiper-slide .objectfit-img {
      max-height: none;
      height: 100%;
    }
  }
  &.objectfit-header {
    height: auto;
    &.standard {
      height: 50vw;
    }
  }

  .pageheader-outer-wrapper {
    margin-top: -10px;
    .measure--lg {
      position: relative; 
    }
    .pageheader-text-wrapper {
      h1 { 
        text-align: center;
      }
      .pageheader-copy {
        @include calc-font-size(27px, 17px);
        line-height: 1.353em;
      }
    }
  }

  .headerswiper-container #headerswiper .swiper-slide .text {
    display: none;
  }

  .headerswiper-container, .headerswiper-container #headerswiper .swiper-slide {
    height: 100%;
  }

  // ANCHOR TABLET
  @media (min-width: $viewport-l) {
    .pageheader-outer-wrapper {
      margin-top: -110px;
    }
  }

  // ANCHOR DESKTOP
  @media (min-width: $viewport-xl) {
    height: 70vh;
    &.objectfit-header {
      height: 70vh;
      &.standard {
        height: 50vh;
      }
    }
    #headerswiper .swiper-slide .slider-outer-wrapper,
    .pageheader-outer-wrapper {
      margin-top: 0;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      .measure--lg {
        height: 100%;
      }
    }
    .slider-text-wrapper {
      position: relative;
      height: 100%;
      width: 100%;
    }
    .headerswiper-container #headerswiper .swiper-slide .text,
    .pageheader-outer-wrapper .pageheader-text-wrapper {
      display: block;
      position: absolute;
      top: auto;
      left: auto;
      top: 50%;
      //left: 0;
      transform: translate(0,-50%);
      &#text-slide2 {
        left: auto;
        right: 0;
        max-width: 800px;
      }
      h1, h2 {
        line-height: 1.043em;
        text-shadow: 1px 1px 20px rgba($color-2,.2);
      }
      h2 {
        font-size: 6.563rem;
      }
    }
    .pageheader-outer-wrapper {
      .measure--lg {
        position: absolute; 
      }
      .pageheader-text-wrapper {
        top: 60%;
        padding-left: 4rem;
        h1 { 
          text-align: left;
        }
        .pageheader-copy {
          line-height: 1.481em;
        }
      }
    }
  }
}
// !SECTION SLIDER / PAGE HEADER

// SECTION SERVICES

.sevice-devider1 {
  background: rgba(88,88,88,0);
  background: -moz-linear-gradient(left, rgba(88,88,88,0) 0%, rgba(0,0,0,0.93) 38%, rgba(0,0,0,0.89) 61%, rgba(88,88,88,0) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(88,88,88,0)), color-stop(38%, rgba(0,0,0,0.93)), color-stop(61%, rgba(0,0,0,0.89)), color-stop(100%, rgba(88,88,88,0)));
  background: -webkit-linear-gradient(left, rgba(88,88,88,0) 0%, rgba(0,0,0,0.93) 38%, rgba(0,0,0,0.89) 61%, rgba(88,88,88,0) 100%);
  background: -o-linear-gradient(left, rgba(88,88,88,0) 0%, rgba(0,0,0,0.93) 38%, rgba(0,0,0,0.89) 61%, rgba(88,88,88,0) 100%);
  background: -ms-linear-gradient(left, rgba(88,88,88,0) 0%, rgba(0,0,0,0.93) 38%, rgba(0,0,0,0.89) 61%, rgba(88,88,88,0) 100%);
  background: linear-gradient(to right, rgba(88,88,88,0) 0%, rgba(0,0,0,0.93) 38%, rgba(0,0,0,0.89) 61%, rgba(88,88,88,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#585858', endColorstr='#585858', GradientType=1 );
}

.sevice-devider2 {
  background: rgba(88,88,88,1);
  background: -moz-linear-gradient(left, rgba(88,88,88,1) 0%, rgba(121,121,121,0.93) 38%, rgba(121,121,121,0.89) 61%, rgba(88,88,88,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(88,88,88,1)), color-stop(38%, rgba(121,121,121,0.93)), color-stop(61%, rgba(121,121,121,0.89)), color-stop(100%, rgba(88,88,88,1)));
  background: -webkit-linear-gradient(left, rgba(88,88,88,1) 0%, rgba(121,121,121,0.93) 38%, rgba(121,121,121,0.89) 61%, rgba(88,88,88,1) 100%);
  background: -o-linear-gradient(left, rgba(88,88,88,1) 0%, rgba(121,121,121,0.93) 38%, rgba(121,121,121,0.89) 61%, rgba(88,88,88,1) 100%);
  background: -ms-linear-gradient(left, rgba(88,88,88,1) 0%, rgba(121,121,121,0.93) 38%, rgba(121,121,121,0.89) 61%, rgba(88,88,88,1) 100%);
  background: linear-gradient(to right, rgba(88,88,88,1) 0%, rgba(121,121,121,0.93) 38%, rgba(121,121,121,0.89) 61%, rgba(88,88,88,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#585858', endColorstr='#585858', GradientType=1 );
}
@media (min-width: $viewport-xl) {
  .sevice-devider1-vertical {
    background: rgba(88,88,88,0);
    background: -moz-linear-gradient(top, rgba(88,88,88,0) 0%, rgba(0,0,0,0.93) 38%, rgba(0,0,0,0.89) 61%, rgba(88,88,88,0) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(88,88,88,0)), color-stop(38%, rgba(0,0,0,0.93)), color-stop(61%, rgba(0,0,0,0.89)), color-stop(100%, rgba(88,88,88,0)));
    background: -webkit-linear-gradient(top, rgba(88,88,88,0) 0%, rgba(0,0,0,0.93) 38%, rgba(0,0,0,0.89) 61%, rgba(88,88,88,0) 100%);
    background: -o-linear-gradient(top, rgba(88,88,88,0) 0%, rgba(0,0,0,0.93) 38%, rgba(0,0,0,0.89) 61%, rgba(88,88,88,0) 100%);
    background: -ms-linear-gradient(top, rgba(88,88,88,0) 0%, rgba(0,0,0,0.93) 38%, rgba(0,0,0,0.89) 61%, rgba(88,88,88,0) 100%);
    background: linear-gradient(to bottom, rgba(88,88,88,0) 0%, rgba(0,0,0,0.93) 38%, rgba(0,0,0,0.89) 61%, rgba(88,88,88,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#585858', endColorstr='#585858', GradientType=0 );
  }
  .sevice-devider2-vertical {
    background: rgba(88,88,88,1);
    background: -moz-linear-gradient(top, rgba(88,88,88,1) 0%, rgba(121,121,121,0.93) 38%, rgba(121,121,121,0.89) 61%, rgba(88,88,88,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(88,88,88,1)), color-stop(38%, rgba(121,121,121,0.93)), color-stop(61%, rgba(121,121,121,0.89)), color-stop(100%, rgba(88,88,88,1)));
    background: -webkit-linear-gradient(top, rgba(88,88,88,1) 0%, rgba(121,121,121,0.93) 38%, rgba(121,121,121,0.89) 61%, rgba(88,88,88,1) 100%);
    background: -o-linear-gradient(top, rgba(88,88,88,1) 0%, rgba(121,121,121,0.93) 38%, rgba(121,121,121,0.89) 61%, rgba(88,88,88,1) 100%);
    background: -ms-linear-gradient(top, rgba(88,88,88,1) 0%, rgba(121,121,121,0.93) 38%, rgba(121,121,121,0.89) 61%, rgba(88,88,88,1) 100%);
    background: linear-gradient(to bottom, rgba(88,88,88,1) 0%, rgba(121,121,121,0.93) 38%, rgba(121,121,121,0.89) 61%, rgba(88,88,88,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#585858', endColorstr='#585858', GradientType=0 );
  }
}

.badge-position {
  position: relative;
  #badge {
    width: 31.25%;
    max-width: 220px;
    right: 0rem;
    bottom: -20px;
    position: absolute;
    z-index: 200;
    box-sizing: content-box;
    @media (min-width: $viewport-l) {
      bottom: -35px;
      right: 6%;
    }
    @media (min-width: $viewport-xl) {
      bottom: -50px;
      padding-right: 4rem;
    }
  }
}

#services {
  position: relative;
  box-shadow: inset 0 60px 70px -30px rgba($color-2--dark, 1);
  background: $color-2 url(/standards/images/background-services.jpg) no-repeat center/cover;
  color: $white;
  opacity : 1;
  padding-top: .5rem;
  .sbox, .sbox-wrapper {
    background: transparent;
  }
  .sbox-devider {
    &--top, &--left {
      height: 2px;
      padding: 0;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        height: 1px;
        @extend .sevice-devider1;
      }
      &::after {
        content: '';
        position: absolute;
        top: 1px;
        display: block;
        width: 100%;
        height: 1px;
        @extend .sevice-devider2;
      }
    }
  }
  header {
    position: relative;
    padding-top: 0;
    padding-left: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 47px;
    &::before {
      content: '';
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 47px;
    }
    &#schnarchtherapie::before {
      //background: url(/standards/images/icon-schlaftherapie.svg) no-repeat center left/100% auto;
      @extend .icon-schnarchtherapie;
    }
    &#behandlungstechnik::before {
      //background: url(/standards/images/icon-behandlungstechnik.svg) no-repeat center left/100% auto;
      @extend .icon-behandlungstechnik;
    }
    &#managertermin::before {
      //background: url(/standards/images/icon-managertermin.svg) no-repeat center left/100% auto;
      @extend .icon-managertermin;
    }
    &#angstpatient::before {
      //background: url(/standards/images/icon-angstpatienten.svg) no-repeat center left/100% auto;
      @extend .icon-angstpatient;
    }
    h3 {
      padding-right: 1rem;
    }
  }
  .accordion-content {
    text-align: center;
    //height: 100%;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 auto;
    p {
      text-align: left;
    }
  }

  .services-button--round {
    width: 3.625rem;
    height: 3.625rem;
    align-self: center;
    display: inline-block;
    margin-top: 1rem;
  }
  // ANCHOR TABLET
  @media (min-width: $viewport-l) {
    .sbox-devider {
      &--top, &--left {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }
  // ANCHOR DESKTOP
  @media (min-width: $viewport-xl) {
    padding-top: 0;
    box-shadow: inset 224px -158px 224px -120px rgba($color-2--dark, 1), inset -224px 158px 264px -120px rgba($color-2--dark, 1);
    .accordions-responsive {
      padding-bottom: 2rem;
    }
    .sbox-devider {
      &--top {
        display: none;
      }
      &--left {
        margin:0;
        width: 2px;
        height: 100%;
        float: left;
        &::before {
          top: auto;
          left: 1px;
          height: 100%;
          width: 1px;
          @extend .sevice-devider1-vertical;
        }
        &::after {
          content: '';
          top: auto;
          left: 0;
          height: 100%;
          width: 1px;
          @extend .sevice-devider2-vertical;
        }
      }
    }
    header {
      padding-top: 115px;
      padding-left: 0;
      &::before {
        display: block;
        max-height: 78px; 
        left: auto;
        max-width: none;
      }
      h3 {
        max-width: 320px;
        margin: 0 auto;
        //padding-bottom: 2rem;
        padding-right: 0;
        text-align: center;
      }
      &#schnarchtherapie::before,
      &#behandlungstechnik::before,
      &#managertermin::before,
      &#angstpatient::before {
        background-position: top center;
        background-size: auto 100%;
      }
    }
    .accordion-content {
      padding-top: .5rem;
      display: flex;
    }
    .accordion-content p {
      text-align: center;
    }
  }
}
// !SECTION SERVICES ENDE

// SECTION PRAXIS
#praxis {
  .praxis-position{
    position: relative;
  }
  .js-smooth-scroll {
    right: 0;
    left: auto;
  }
}
@media (min-width: $viewport-xl) {
  .headline-left-align-right {
    max-width: 0px;
    overflow: visible;
  }
}
// !SECTION PRAXIS ENDE


// SECTION KONTAKT

#kontakt {
  padding: 1.5rem 0;
}
.adress {
  font-size: 14px; 
  line-height: 1.5rem;
}
.adress-text-left {
  padding-bottom: 1rem;
  line-height: 1.571em;
}
.adress-text-right {
  padding-bottom: 2rem;
  line-height: 1.571em;
}
.adress-text-center {
  padding-bottom: 1rem;
  line-height: 1.571em;
}
.contact-phone {
  padding-left: 33px;
  height: 16px;
  position: relative;
  &::before {
    content: '';
    display: inline-block;
    background: url('/standards/images/icon-kontakt-phone.svg') no-repeat left top/100% 100%;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 2px;
  }
}
.contact-mail {
  padding-left: 33px;
  height: 14px;
  position: relative;
  &::before {
    content: '';
    display: inline-block;
    background: url('/standards/images/icon-kontakt-mail.svg') no-repeat left top/100% 100%;
    width: 19px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 2px;
  }
}
input[type="checkbox"] {
  display:none;
}

input[type="checkbox"] + span {
display: flex;
justify-content: left;
align-items: center;
flex-wrap: wrap;
 &::before {
    width: 15px;
    height: 15px;
    background-color: $color-0--d1;
    display: block;
    content: "";
    float: left;
    margin-right: 12px;
  }
}
.data-protection.error input[type=checkbox]+span::before {
  background: #ff3535;
}

input[type="checkbox"]:checked + span::before,
.data-protection.error input[type=checkbox]:checked+span::before {
  background: url('/standards/images/check.svg') no-repeat top left/auto 100%;
}

.form-v2#contactform {
  .input__label--hoshi-color.error::after,
  .input__label--hoshi.error::after,
  .input__label--hoshi.error::before, 
  .select-box.error {
    border-bottom: 1px solid #ff3535;
  }
}

// ANCHOR DESKTOP
 @media (min-width: $viewport-xl) {
  #kontakt {
    padding: 4rem 0;
  }
  .adress {
    font-size: 18px; 
    line-height: 2rem;
  }
  .adress-text-left {
    text-align: right;
    padding-right: 4rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
    div {
      text-align: left;
      float: right;
    }
  }
  .adress-text-right {
    text-align: left;
    padding-left: 4rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
  .adress-text-center {
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
  }
  .contact-phone {
    padding-left: 38px;
    height: 20px;
    a {
      color: inherit;
      font: inherit;
    }
    &::before {
      width: 38px;
      height: 20px;
    }
  }
  .contact-mail {
    padding-left: 38px;
    height: 18px;
    a {
      color: inherit;
      font: inherit;
    }
    &::before {
      width: 38px;
      height: 18px;
    }
  }
}
// !SECTION KONTAKT ENDE


// SECTION LEISTUNGSSEITEN
.content-position {
  position: relative;
}

.sitelabel {
  position: absolute; 
  top: 90px;
  z-index: 20;
  left: 0;
  height: 17.2%;
  width: 37.5%;
  max-height: 18.75vw;
  //max-width: 120px;
  background: url(/standards/images/background-sitelabel.png) no-repeat center right/cover;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
  }

  &#schnarchtherapie::before,
  &#behandlungstechnik::before,
  &#managertermin::before,
  &#angstpatient::before {
    background-position: center center;
    background-size: auto 60%;
  }

  &#schnarchtherapie::before {
    @extend .icon-schnarchtherapie;
  }
  &#behandlungstechnik::before {
    @extend .icon-behandlungstechnik;
  }
  &#managertermin::before {
    @extend .icon-managertermin;
  }
  &#angstpatient::before {
    @extend .icon-angstpatient;
  }

  // ANCHOR TABLET
  @media (min-width: $viewport-l) {
    width: 37.5%;
    max-height: 150px;
    max-width: 240px;
    top: 12%;

    &#schnarchtherapie::before,
    &#behandlungstechnik::before,
    &#managertermin::before,
    &#angstpatient::before {
      background-position: center center;
    }
  }

  // ANCHOR DESKTOP
  @media (min-width: $viewport-xl) {
    max-width: 310px;
  }
  
}

.pageheader-glow {
  background: rgba(255,255,255,1); 
  box-shadow: 0px 0px 20px 30px rgba(255,255,255,1); 
  -moz-box-shadow:0px 0px 20px 30px rgba(255,255,255,1); 
  -webkit-box-shadow:0px 0px 20px 30px rgba(255,255,255,1);
}
// ANCHOR TABLET (768)
@media (min-width: $viewport-l) {
  .pageheader-glow { 
    box-shadow: 0px 0px 50px 60px rgba(255,255,255,1); 
    -moz-box-shadow: 0px 0px 50px 60px rgba(255,255,255,1); 
    -webkit-box-shadow: 0px 0px 50px 60px rgba(255,255,255,1); 
  }

}
// ANCHOR DESKTOP (1024)
@media (min-width: $viewport-xl) {
  .pageheader-glow {
    background: transparent; 
    box-shadow: none; 
    -moz-box-shadow: none; 
    -webkit-box-shadow: none; 
  }
  #pageheader {
    .pageheader-outer-wrapper {
      .measure--lg { 
        top: 65%;
        height: 
        auto;
        transform: translate(0,-50%); 
      }
      .pageheader-text-wrapper { 
        position: relative; 
        top: auto; 
        transform: none;
        .pageheader-copy, h1 {
          text-shadow: 0px 0px 12px rgba(255,255,255,1);
          -moz-text-shadow: 0px 0px 25px rgba(255,255,255,1); 
          -webkit-text-shadow: 0px 0px 25px rgba(255,255,255,1);
        }
        .pageheader-copy {
          margin-bottom: 1.5em;
          margin-top:0;
        }
      }
    }
  }
}


// !SECTION LEISTUNGSSEITEN ENDE

// SECTION STANDARD-TEMPLATE

#standardcontent {
  section.content-section {
    padding: 1rem 0;
  }
}

// ANCHOR DESKTOP
@media (min-width: $viewport-xl) {
  #standardcontent {  
    section.content-section {
      h2 {
        margin-top: 2rem;
      }
    }
  }
}

// !SECTION STANDARD-TEMPLATE ENDE


// SECTION FAQ
.inhouse-wrapper { 
  padding-top: 2rem;
  padding-bottom: 2rem;
}
ul.faq {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    @include calc-font-size(34px, 21px);
    line-height: 1.147em;
    margin-bottom: 1em;
    padding-left: 1.5em;
    @extend .font-rufo;
    @extend .font--uppercase;
    @extend .fontcolor-white;
    position: relative;
    &::before {
      content: '';
      background: url('/standards/images/check.svg') no-repeat top left/100% auto;
      width: 1em;
      height: 100%;
      position: absolute;
      left: 0;
      top: -.2em;
    }
    .faq-descr {
      display: none;
      position: absolute;
      background: $color-1;
      padding: 1.5rem 2.3rem 1.5rem 1.5rem; 
      left: 0;
      margin-top: 16px;
      z-index: 200;
      color: $color-2--dark;
      text-transform: none;
      font-size: 1rem;
      line-height: 1.438em;
      font-family: Helvetica,Arial,sans-serif;
      width: 100%;
      top: 100%;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
      &::before {
        content: '';
        position: absolute;
        border-style: solid;
        left: 2em;
        top: -10px;
        border-width: 0 10px 10px;
        border-color: transparent transparent $color-1;
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
      }
      .close {
        position: absolute;
        right: .75rem;
        top: .75rem;
        content: '';
        background: url(/standards/images/close.svg) no-repeat center center/cover;
        width: 19px;
        height: 17px;
        cursor: pointer;
      }
    }

    // ANCHOR DESKTOP

    @media (min-width: $viewport-xl) {
      display: flex;
      align-items: center;
      float: left;
      clear: both;
      .faq-descr {
        margin-left: 20px;
        margin-top: 0;
        left: 100%;
        top: auto;
        width: auto;
        min-width: 250px;
        margin-left: 20px;
        &::before {
          right: auto;
          left: -16px;
          top: calc(50% - 25px);
          border-width: 25px 16px 25px 0;
          border-color: transparent $color-1 transparent transparent;
        }
      }
    }
  }
}
@media (min-width: $viewport-xl) {
  .inhouse-wrapper { 
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
// !SECTION FAQ ENDE

// SECTION TEAM

.team-inner-container {
  position: relative;
  overflow: visible;
  display: flex;
  align-items: center;
  .team-text {
    position: absolute;
    /*left: 0;
    width: 65%;
    padding-left: 8.5%;
    top: 28%;
    */
    left: 50%;
    transform: translateX(-50%);
    width: 68%;
    padding-left: 0;
    top: 28%;
    text-align: center;
    padding-top: 1rem;
    .name {
      @include calc-font-size(54px, 26px);
      line-height: 100%;
    }
  }
}

#galerie {
  position: relative;
}
#galerieswiper {
  overflow: hidden;
  .swiper-slide {
    width: 33%;
    max-height: 346px;
  }
}
// ANCHOR Tablet
@media (min-width: $viewport-l) {
  .team-inner-container {
    .team-text {
      top: auto;
    }
  }
}

// ANCHOR DESKTOP
@media (min-width: $viewport-xl) {
  .team-container {
    margin-top: 1.8rem;
    margin-bottom: 1.4rem;
  }
}
// !SECTION TEAM ENDE

// SECTION FOOTER
.fixed-bottom-el {
  background: $white;
  .outer {
    height: 100%;
  }
}

footer {
  height: 100%;
  .footer-devider {
    &::after {
      content: '|';
      padding: 0 1rem;
    }
  }
  display: flex;
  flex-direction: column;
  #footernav {
    order: 1;
    @extend .font--center;
    padding: 1rem 0;
  }
  #footerlogo {
    order: 3;
    @extend .font--center;
    padding: 1rem 0;
    img {
      max-width: 130px;
    }
    .social-nav{
      justify-content: space-around;
      justify-content: space-evenly !important;
    }
  }
  #footercontact {
    order: 2;
    @extend .font--center;
    @extend .font-rufo;
    color: $color-2;
    padding: 0 0 1rem 0;
  }

  // ANCHOR DESKTOP
  @media (min-width: $viewport-xl) {
    height: 100%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    #footernav {
      text-align: left;
    }
    #footerlogo {
      order: 2;
      padding: 1rem 0;
    }
    #footercontact {
      order: 3;
      text-align: right;
      padding-top: 1rem;
    }
  }  
}

// !SECTION FOOTER ENDE