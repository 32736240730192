// out: false, main: ../../style.scss
///////////////////////////////////////
////////////// Form V2 ////////////////
///////////////////////////////////////
$input-label-border-color-active: $color-0--d1;
$std-form-font-size: "14px";
$std-form-font-color: $color-0--d1;
$std-form-border-color-highlight: $color-1;
$form-input-field-fill-color: $color-2;

.form-v2 {
  background-color: $color-2;
  color: $std-form-font-color;
  .form-msg {
    font-weight: bold;
    margin-top: 1rem;
    &.error {
      color: $color--error;
    }
    &.success {
      color: $color--success;
    }
  }
  #pflichtfeld-info {
    font-size: 0.875rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  &#contactform {
    justify-content: space-between;
    .select-box,
    .input__label--hoshi::before,
    .input__label--hoshi::after,
    .input__label--hoshi-color::after {
      border-bottom: 1px solid $color-0--d1;
    }
    .data-protection-submit {
      margin: 2rem 0;
      .data-protection {
        position: relative;
        span {
          //padding-left: 1.3rem;
          // iOS
          @supports (-webkit-overflow-scrolling: touch) {
            //padding-left: 1.6rem;
          }
        }
      }
      input {
        position: absolute;
        top: 0.3rem;
        &.checkbox {
          margin-bottom: 0;
          vertical-align: middle;
        }
      }
    }
    .select-box {
      position: relative;
      padding-right: 0;
      padding-bottom: 0;
      margin-bottom: 0.5em;
      &::after {
        position: absolute;
        content: "";
        display: inline-block;
        background-image: url("/standards/images/arrow-down.png");
        background-repeat: no-repeat;
        height: 12px;
        width: 12px;
        right: 0;
        bottom: 11px;
        //-webkit-transform: rotate(0deg);
        //transform: rotate(0deg);
        -webkit-transform-origin: center;
                transform-origin: center;
        -webkit-transition: -webkit-transform 0.2s ease-in-out;
        transition: -webkit-transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
        pointer-events: none;
        @supports (color: black){
          background-image: url("/standards/images/arrow-down.svg");
        }
      }
      &.select-box--active {
        border-bottom: 1px solid $color-0--d1;
        &::after {
          //-webkit-transform: rotate(-90deg);
          //transform: rotate(-90deg);
          -webkit-transform-origin: center;
                  transform-origin: center;
          -webkit-transition: -webkit-transform 0.2s ease-in-out;
          transition: -webkit-transform 0.2s ease-in-out;
          transition: transform 0.2s ease-in-out;
          transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
        }
      }
      select {
        padding: .5rem 0;
        //padding-left: .25rem;
        background-color: $form-input-field-fill-color;
      }
    }
    .input__label--hoshi::after {
      border-bottom: 2px solid $color-1;
    }
  }
  .select-box {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: 0;
    //max-width: 95%;
    //width: calc(100% - 2em);
    width: 100%;
    vertical-align: top;
    margin-bottom: 1em;
    padding: .25em;
    padding-left: 0;
    border: 0;
    border-bottom: 1px solid $std-form-border-color-highlight;
    vertical-align: top;
    font-weight: bold;
    &.select-box--active {
      border-bottom: 2px solid $std-form-border-color-highlight;
    }
    .select {
      border: 0;
      outline: 0;
      margin: 0;
      margin-top: 5px;
      padding: 0;
      font-size: 14px;
      @media (min-width: $viewport-xl) {
        font-size: 18px;
        margin-top: 28px;
      }
      font-weight: 500;
      background: transparent;
      background-image: none;
      -webkit-appearance: none;
      option {
        font-family: $font-family;
        font-weight: 500;
        font-size: 14px;
        @media (min-width: $viewport-xl) {
          font-size: 18px;
        }     
      }
    }
  }
  .input {
  	position: relative;
  	z-index: 1;
  	display: inline-block;
  	margin: 0;
  	max-width: 100%;
    width: 100%;
    vertical-align: top;
    &.textarea {
      margin-top: 1.6rem;
    }
  }

  .input__field {
  	position: relative;
  	display: block;
  	float: right;
  	padding: 0.8em;
  	width: 100%;
  	border: none;
  	border-radius: 0;
  	background: #f0f0f0;
    font-weight: 500;
  	-webkit-appearance: none; /* for box shadows to show on iOS */
  }
  textarea {
    margin-top: 2rem;
    resize: none;
    background: $color-0--d1;
    color: $color-2;
    padding: 0.5rem;
    font-size: 14px;
    outline: none;
  }
 

  .input__field:focus {
  	outline: none;
  }

  .input__label {
  	display: inline-block;
  	padding: 0 1em;
  	width: 40%;
    font-weight: 500;
    font-family: $font-family;
  	font-size: 14px;
    @media (min-width: $viewport-xl) {
      font-size: 18px;
    }
  	-webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  	-webkit-touch-callout: none;
  	-webkit-user-select: none;
  	-moz-user-select: none;
  	-ms-user-select: none;
  	user-select: none;
  }

  .input__label-content, .input__label-content-textarea {
  	position: relative;
  	display: block;
  	padding: 1em 0;
    width: 100%;
  }
  .input__label-content-textarea {
    top: 0;
    bottom: auto;
    padding: 0;
  }
  /* Hoshi */
  .input--hoshi {
    overflow: hidden;
  }

  .input__field--hoshi {
  	margin-top: 2em;
  	padding: 0.85em 0.15em;
  	width: 100%;
  	background: $form-input-field-fill-color;
  }

  .input__label--hoshi, .input__label-textarea {
  	position: absolute;
  	bottom: 0;
  	left: 0;
  	padding: 0;
  	width: 100%;
  	height: calc(100% - 1em);
  	text-align: left;
    pointer-events: none;
    
  }
  .input__label-textarea {
    top: 0;
    bottom: auto; 
  }

  .input__label-content--hoshi {
    position: absolute;
    bottom: 1px;
  }

  .input__label--hoshi::before,
  .input__label--hoshi::after {
  	content: '';
  	position: absolute;
    top: 0;
  	left: 0;
  	width: 100%;
  	height: calc(100% - 10px);
  	border-bottom: 1px solid $std-form-border-color-highlight;
  }

  .input__label--hoshi::after {
  	//margin-top: 2px;
  	border-bottom: 2px solid $std-form-border-color-highlight;
  	-webkit-transform: translate3d(-100%, 0, 0);
  	transform: translate3d(-100%, 0, 0);
  	-webkit-transition: -webkit-transform 0.3s;
  	transition: -webkit-transform 0.3s;
  	transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    bottom: 1px;
  }

  .input__label--hoshi-color::after {
  	border-color: $std-form-border-color-highlight;
  }

  .input__field--hoshi:focus + .input__label--hoshi::after,
  .input--filled .input__label--hoshi::after {
  	-webkit-transform: translate3d(0, 0, 0);
  	transform: translate3d(0, 0, 0);
  }

  .input__field--hoshi:focus + .input__label--hoshi .input__label-content--hoshi,
  .input--filled .input__label-content--hoshi {
  	-webkit-animation: inputLabelAnimation 0.3s forwards;
  	animation: inputLabelAnimation 0.3s forwards;
  }

  $input-hoshi-keyframes-x: 1em;
  $input-hoshi-keyframes-y: -50%;

  @keyframes inputLabelAnimation {
    50% {
  		opacity: 0;
  		-webkit-transform: translate($input-hoshi-keyframes-x, 0);
  		transform: translate($input-hoshi-keyframes-x, 0);
  	}
  	51% {
  		opacity: 0;
  		-webkit-transform: translate(-#{$input-hoshi-keyframes-x}, $input-hoshi-keyframes-y);
  		transform: translate(-#{$input-hoshi-keyframes-x}, $input-hoshi-keyframes-y);
  	}
  	100% {
  		opacity: 1;
  		-webkit-transform: translate(0, $input-hoshi-keyframes-y);
  		transform: translate(0, $input-hoshi-keyframes-y);
  	}
  }
}